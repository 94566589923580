// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-avvisi-tsx": () => import("./../../../src/pages/avvisi.tsx" /* webpackChunkName: "component---src-pages-avvisi-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-materiale-tsx": () => import("./../../../src/pages/materiale.tsx" /* webpackChunkName: "component---src-pages-materiale-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-temiesame-tsx": () => import("./../../../src/pages/temiesame.tsx" /* webpackChunkName: "component---src-pages-temiesame-tsx" */),
  "component---src-templates-academic-year-tsx": () => import("./../../../src/templates/academicYear.tsx" /* webpackChunkName: "component---src-templates-academic-year-tsx" */)
}

